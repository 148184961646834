import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Input from "../../components/FormComponents/Input";
import Button from "../../components/FormComponents/Button";
import { postCompanyUser } from "../../actions/company";

const FormGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, 264px);
  grid-row-gap: 16px;
  grid-column-gap: 26px;
`;

function CompanyUserAdd() {
  const { companyUUID } = useParams<{ companyUUID: string }>();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting },
  } = useForm<any>();
  const onSubmit = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    if (Object.keys(errors).length > 0 || isSubmitting) {
      return;
    }
    await postCompanyUser(dispatch, {
      companyUuid: companyUUID,
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
      },
    })
      .then((response) => {
        if (response) {
          setValue("name", "");
        }
      })
      .catch(() => {});
  };

  return (
    <FormGrid onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="email"
        label="Email"
        inputRef={register({ required: true })}
        type="text"
      />
      <Input
        id="firstName"
        label="First name"
        inputRef={register({ required: true })}
        type="text"
      />
      <Input
        id="lastName"
        label="Last name"
        inputRef={register({ required: true })}
        type="text"
      />
      <Input
        id="password"
        label="Password"
        inputRef={register({ required: true })}
        type="password"
      />
      <Button type="submit" size="small" width="250px" disabled={isSubmitting}>
        {isSubmitting ? "Loading..." : "Add"}
      </Button>
    </FormGrid>
  );
}

export default CompanyUserAdd;
