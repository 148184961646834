import React from "react";
import styled from "styled-components";
import coffee from "../assets/images/coffee.png";

const Logo = styled.div`
  font-size: 27px;
  font-weight: bold;
  color: #000;
  margin-bottom: 100px;
  position: relative;
  padding-top: 8px;
  &::before {
    content: " ";
    height: 3px;
    width: 34px;
    left: 2px;
    top: 0;
    border-radius: 0;
    position: absolute;
    background: #000;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  max-width: 1440px;
  overflow: hidden;
  @media (max-width: 1439px) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Info = styled.div`
  padding: 80px;
  position: relative;
  @media (max-width: 1439px) {
    padding: 16px;
  }
`;

const LogOut = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  text-decoration-line: underline;
  cursor: pointer;
  text-align: right;
`;

function MainLayout({ children, leftContent, rightContent }: any) {
  const logOutUser = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <Wrapper>
      <Grid>
        <Info>
          <Logo>ESTCAP INVESTMENT MANAGEMENT</Logo>
          {children}
          {leftContent}
        </Info>
        <div>
          {rightContent ? (
            <Info>
              <LogOut onClick={logOutUser}>Log out</LogOut>
              {rightContent}
            </Info>
          ) : (
            <img alt="coffee" src={coffee} />
          )}
        </div>
      </Grid>
    </Wrapper>
  );
}

export default MainLayout;
