import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import MainLayout from "../components/MainLayout";
import Button from "../components/FormComponents/Button";
import Input from "../components/FormComponents/Input";
import Footer from "../components/Footer";
import { loginUser } from "../api/user";

const Heading = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin-bottom: 32px;
`;

const SignInParagraph = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 162px;
`;

const FormGrid = styled.form`
  display: grid;
  grid-row-gap: 16px;
`;

type Inputs = {
  username: string;
  password: string;
};

function Login() {
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const [error, setError] = useState("");

  const logIn = (data: Inputs) => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    loginUser(data)
      .then(() => {
        window.location.href = "/dashboard";
      })
      .catch(() => {
        setError(() => "Email or password wrong!");
      });
  };
  return (
    <MainLayout>
      <Heading>Sign In</Heading>
      <p style={{ fontSize: 16, marginBottom: 19 }}>
        Enter your email and password to sign in.
      </p>
      <FormGrid onSubmit={handleSubmit(logIn)}>
        <Input
          id="username"
          label="Email"
          type="email"
          inputRef={register({ required: true })}
        />
        <Input
          id="password"
          label="Password"
          type="password"
          inputRef={register({ required: true })}
        />
        <div style={{ marginTop: 14 }}>
          {error && <p style={{ color: "red", margin: 0 }}>{error}</p>}
          <Button size="small" width="250px" type="submit">
            Sign in
          </Button>
        </div>
      </FormGrid>
      <SignInParagraph>
        Don’t have an account yet?{" "}
        <Link to="/register">
          <span style={{ textDecoration: "underline", color: "#000" }}>
            Register here
          </span>
        </Link>{" "}
        →
      </SignInParagraph>
      <Footer />
    </MainLayout>
  );
}

export default Login;
