import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use(
  (config) => {
    const newConfig = config;
    if (window.localStorage.getItem("bearerToken")) {
      newConfig.headers.Authorization = `Bearer ${window.localStorage.getItem(
        "bearerToken"
      )}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default API;
