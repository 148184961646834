import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import MainLayout from "../../components/MainLayout";
import { getCompanyUsers } from "../../actions/company";
import { CompanyUser } from "../../models/company";
import { RootState } from "../../store";
import Loader from "../../components/Loader";
import CompanyUserAdd from "./CompanyUserAdd.tsx";
import { clearCompanyUsers } from "../../reducers/company/companyReducer";

const Heading = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #000;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
`;

const TableHeading = styled.th`
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  color: #666;
  padding: 10px 16px;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid #000;
`;

const TableData = styled.td`
  border-bottom: 1px solid #000;
  padding: 23px 16px;
`;

interface ParamTypes {
  companyUUID: string;
}

function CompanyUsers() {
  const dispatch = useDispatch();

  const { companyUUID } = useParams<ParamTypes>();

  useEffect(() => {
    dispatch(clearCompanyUsers());
    getCompanyUsers(dispatch, companyUUID);
  }, [companyUUID, dispatch]);

  const users: CompanyUser[] = useSelector((state: any) => state.company.users);

  const loading: boolean = useSelector(
    (state: RootState) => state.company.loading
  );

  const leftContent = (
    <>
      <Heading>Users</Heading>
      <Table>
        <TableHead>
          <tr>
            <TableHeading>Last name</TableHeading>
            <TableHeading>First name</TableHeading>
            <TableHeading>Email</TableHeading>
          </tr>
        </TableHead>
        <tbody>
          {users && users.length
            ? users.map((user) => (
                <tr key={user.uuid}>
                  <TableData style={{ fontWeight: "bold" }}>
                    {user.lastName}
                  </TableData>
                  <TableData>{user.firstName}</TableData>
                  <TableData>{user.email}</TableData>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      {loading && <Loader />}
    </>
  );

  const rightContent = (
    <>
      <div style={{ marginTop: 16 }}>
        Add user:
        <CompanyUserAdd />
      </div>
    </>
  );

  return <MainLayout leftContent={leftContent} rightContent={rightContent} />;
}

export default CompanyUsers;
