import React from "react";
import styled from "styled-components";
import MainLayout from "../components/MainLayout";

const Heading = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #000;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
`;

const TableHeading = styled.th`
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  color: #666;
  padding: 10px 16px;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid #000;
`;

const TableData = styled.td`
  border-bottom: 1px solid #000;
  padding: 23px 16px;
`;

const OutlinedButton = styled.div`
  border: 1px solid #000000;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  padding: 4px 22px;
  cursor: pointer;
`;

function Dashboard() {
  const leftContent = (
    <>
      <Heading>Dashboard</Heading>
      <Table>
        <TableHead>
          <tr>
            <TableHeading>Fund name</TableHeading>
            <TableHeading>Type</TableHeading>
            <TableHeading>Units</TableHeading>
          </tr>
        </TableHead>
        <tbody>
          <tr>
            <TableData style={{ fontWeight: "bold" }}>
              Second Best Available Fund
            </TableData>
            <TableData>type</TableData>
            <TableData>1,000</TableData>
            <TableData>
              <OutlinedButton style={{ marginRight: 16 }}>SELL</OutlinedButton>{" "}
              <OutlinedButton>BUY</OutlinedButton>
            </TableData>
          </tr>
        </tbody>
      </Table>
      <p>
        Thank you for registering! <br />
        <br />
        We will review your data and get in touch in case additional information
        is required. <br />
        <br />
        You can always contact us by sending us an email at support@estcap.ee
        <br />
        <br />
        <br />
        EstCap Team
      </p>
    </>
  );

  const rightContent = (
    <>
      <div style={{ marginTop: 141 }}>
        <strong>Buying and selling fund units</strong>
        <br />
        <br />
        Once a buy or sell is initiated, the Fund Manager will be notified and
        will take the transaction forward. After a successful subscription the
        Fund Manager will inform EstCap and the additional units will be added
        to the list.
      </div>
    </>
  );

  return <MainLayout leftContent={leftContent} rightContent={rightContent} />;
}

export default Dashboard;
