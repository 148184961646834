import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const FormLabel = styled.label`
  font-size: 14px;
  color: #333;
`;

const FormInput = styled.input`
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  height: 20px;
  width: 20px;
  padding: 0 12px;
  color: #333;
`;

const CheckboxContainer = styled.div<{ fullWidth: boolean }>`
  display: block;
  font-size: 14px;
  color: #333;
  grid-column: ${(props) => props.fullWidth && "1 / 3"};
`;

interface InputProps {
  readonly label: string;
  readonly type: string;
  readonly id: string;
  readonly inputRef: any;
  readonly fullWidth: boolean;
}

function Checkbox({ label, type, inputRef, id, fullWidth }: InputProps) {
  return (
    <CheckboxContainer fullWidth={fullWidth}>
      <FormInput type={type} id={id} name={id} ref={inputRef} />
      <FormLabel htmlFor={id}>{label}</FormLabel>
    </CheckboxContainer>
  );
}

export default Checkbox;

Checkbox.defaultProps = {
  label: "",
  type: "text",
  inputRef: () => null,
  fullWidth: false,
};

Checkbox.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  fullWidth: PropTypes.bool,
};
