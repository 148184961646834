import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company } from "../../models/company";

type CompaniesState = { data: Company[]; loading: boolean };

export const companySlice = createSlice({
  name: "companies",
  initialState: {
    data: [],
    loading: false,
  } as CompaniesState,
  reducers: {
    setLoading: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = action.payload;
    },
    addCompanies: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.data = action.payload;
    },
    addCompany: (state, action: PayloadAction<Company | null>) => {
      if (action.payload !== null) {
        // eslint-disable-next-line no-param-reassign
        state.data = [...state.data, action.payload];
      }
    },
  },
});

export const { addCompanies, addCompany, setLoading } = companySlice.actions;

export default companySlice.reducer;
