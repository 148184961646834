import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const FormSelect = styled.select`
  display: block;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  min-width: 264px;
  padding: 0 12px;
  color: #333;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #333;
`;

interface InputProps {
  readonly label: string;
  readonly chooseLabel: string;
  readonly id: string;
  readonly inputRef: any;
  readonly options: string[];
}

function Select({ label, inputRef, options, id, chooseLabel }: InputProps) {
  return (
    <div>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <FormSelect id={id} ref={inputRef} name={id} defaultValue={chooseLabel}>
        <option disabled hidden value={chooseLabel}>
          {chooseLabel}
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </FormSelect>
    </div>
  );
}

export default Select;

Select.defaultProps = {
  label: "",
  chooseLabel: "",
  inputRef: () => null,
  options: [],
};

Select.propTypes = {
  label: PropTypes.string,
  chooseLabel: PropTypes.string,
  inputRef: PropTypes.func,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
};
