import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

interface FormButtonProps {
  readonly size: string;
  readonly children: string;
  readonly width: string;
  readonly type: "button" | "submit" | "reset";
  readonly onClick: any;
  readonly disabled: boolean;
}

const FormButton = styled.button<FormButtonProps>`
  background: #000000;
  border-radius: 4px;
  padding: ${(props) => (props.size === "small" ? "6px" : "12px")};
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  outline: none;
  cursor: pointer;
  border: none;
  min-width: 250px;
  width: ${(props) => props.width};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

function Button({
  children,
  size,
  width,
  type,
  onClick,
  disabled,
}: FormButtonProps) {
  return (
    <FormButton
      type={type}
      size={size}
      width={width}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </FormButton>
  );
}

export default Button;

Button.defaultProps = {
  size: "",
  width: "",
  type: "button",
  onClick: () => null,
  disabled: false,
};

Button.propTypes = {
  size: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
