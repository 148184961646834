import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import MainLayout from "../components/MainLayout";
import Input from "../components/FormComponents/Input";
import Button from "../components/FormComponents/Button";
import Select from "../components/FormComponents/Select";
import Checkbox from "../components/FormComponents/Checkbox";
import Paragraph from "../components/FormComponents/Paragraph";
import { registerUser } from "../api/user";

const Heading = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0 0 32px 0;
`;

const StepCounter = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const StepText = styled.p`
  font-size: 16px;
  color: #000;
  margin: 0 0 16px 0;
`;

const FormGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, 264px);
  grid-row-gap: 16px;
  grid-column-gap: 26px;
`;

type Inputs = {
  example: string;
  exampleRequired: string;
};

type InputType = {
  component: string | "input" | "select" | "checkbox" | "paragraph";
  id: string;
  label: string;
  chooseLabel?: string;
  type?: string;
  options?: string[];
  fullWidth?: boolean;
};

const inputs: InputType[][] = [
  [
    {
      id: "username",
      label: "Your Email",
      type: "email",
      component: "input",
    },
    {
      id: "phone",
      label: "Phone number",
      type: "tel",
      component: "input",
    },
    {
      id: "street",
      label: "Street address, P.O. box, c/o",
      component: "input",
    },
    {
      id: "city",
      label: "City/town",
      component: "input",
    },
    {
      id: "zip",
      label: "Zip/Postal code",
      component: "input",
    },
    {
      id: "state",
      label: "State/Province/Region",
      component: "input",
    },
  ],
  [
    {
      id: "residence",
      label: "Country of residence",
      chooseLabel: "Select",
      component: "select",
      options: ["eesti", "muu"],
    },
    {
      id: "taxResidency",
      label: "Tax residency",
      chooseLabel: "Select",
      component: "select",
      options: ["eesti", "muu"],
    },
    {
      id: "sourceOfMyFunds",
      label: "Source of my funds",
      component: "input",
      fullWidth: true,
    },
    {
      id: "IamaPEP",
      label: "I am a PEP",
      component: "checkbox",
      fullWidth: true,
      type: "checkbox",
    },
    {
      id: "Iinvestonbehalfofacompany",
      label: "I invest on behalf of a company",
      component: "checkbox",
      fullWidth: true,
      type: "checkbox",
    },
    {
      id: "companyName",
      label: "Company name",
      component: "input",
    },
    {
      id: "legalType",
      label: "Legal type",
      component: "input",
    },
    {
      id: "Registry code",
      label: "registryCode",
      component: "input",
    },
    {
      id: "countryOfEstablishment",
      label: "Country of establishment",
      chooseLabel: "Select",
      component: "select",
      options: ["eesti", "muu"],
    },
    {
      id: "registrationAddress",
      label: "Registration address",
      component: "input",
      fullWidth: true,
    },
    {
      id: "email",
      label: "Email",
      component: "input",
      type: "email",
    },
    {
      id: "phoneNumber",
      label: "Phone number",
      component: "input",
      type: "phone",
    },
    {
      id: "linkToTheCompanyCertificate",
      label: "Link to the company certificate:",
      component: "input",
      fullWidth: true,
    },
    {
      id: "paragraph",
      label:
        "I hereby certify the accuracy of the data submitted and grant EstCap OÜ and the fund manager my consent to check and process my data on the terms and for the purposes set out in the Fund Investment Agreement and Fund rules.",
      component: "paragraph",
    },
  ],
];

const Components = {
  input: Input,
  select: Select,
  checkbox: Checkbox,
  paragraph: Paragraph,
};

function Register() {
  const [step, setStep] = useState(0);

  const { register, handleSubmit, errors } = useForm<Inputs>();
  const onSubmit = (data: Inputs) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    registerUser(data)
      .then((response) => {
        if (response) {
          if (inputs.length === step + 1) {
            window.location.href = "/dashboard";
            return;
          }
          setStep((prevStep) => prevStep + 1);
        }
      })
      .catch(() => {
        setStep((prevStep) => prevStep);
      });
  };

  return (
    <MainLayout>
      <StepCounter>Step {step + 1}</StepCounter>
      <Heading>Account details</Heading>
      <StepText>
        Please create a password. You will need it to log in next time.
      </StepText>
      {step === 0 && (
        <div style={{ marginBottom: 40 }}>
          <Input
            id="password"
            label="Password"
            inputRef={register({ required: true })}
            type="password"
          />
        </div>
      )}
      <FormGrid onSubmit={handleSubmit(onSubmit)}>
        {inputs[step].map((input: InputType) => {
          // @ts-ignore
          const CustomComponent = Components[input.component];
          return (
            <CustomComponent
              key={input.id}
              id={input.id}
              label={input.label}
              inputRef={register({ required: true })}
              type={input.type || "text"}
              options={input.options}
              chooseLabel={input.chooseLabel}
              fullWidth={input.fullWidth}
            />
          );
        })}
        <Button type="submit" size="small" width="250px">
          Submit and continue
        </Button>
      </FormGrid>
    </MainLayout>
  );
}

export default Register;
