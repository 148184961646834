/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company, CompanyUser } from "../../models/company";

type CompaniesState = {
  company: Company | null;
  users: CompanyUser[];
  loading: boolean;
};

export const companySlice = createSlice({
  name: "company",
  initialState: {
    company: null,
    users: [],
    loading: false,
  } as CompaniesState,
  reducers: {
    setUsersLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;

      // sometimes api does not send users array
      if (action.payload.users) {
        state.users = action.payload.users;
      } else {
        state.users = [];
      }
    },
    clearCompanyUsers: (state) => {
      state.users = [];
    },
    addUserToCompany: (state, action: PayloadAction<CompanyUser>) => {
      state.users = [...state.users, action.payload];
    },
  },
});

export const {
  setCompany,
  setUsersLoading,
  addUserToCompany,
  clearCompanyUsers,
} = companySlice.actions;

export default companySlice.reducer;
