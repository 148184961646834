import API from "./api";

interface UserLogin {
  username: string;
  password: string;
}

export const loginUser = async ({ username, password }: UserLogin) => {
  return API.post("auth/login", {
    username,
    password,
  })
    .then((res) => {
      if (res.data.payload?.bearerToken) {
        localStorage.setItem("bearerToken", res.data.payload.bearerToken);
        return res.data;
      }

      if (res.data.error) {
        localStorage.clear();
        window.location.href = "/";
        return null;
      }

      return null;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const registerUser = async (data: any) => {
  return API.post("auth/register", data)
    .then((res) => {
      if (res.data.payload) {
        if (res.data.payload.bearerToken) {
          localStorage.setItem("bearerToken", res.data.payload.bearerToken);
        }

        return res.data.payload;
      }

      if (res.data.error) {
        localStorage.clear();
        window.location.href = "/";
        return null;
      }

      return null;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
