import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ParagraphStyled = styled.p`
  font-size: 16px;
  grid-column: 1 / 3;
`;

interface ParagraphProps {
  readonly label: string;
}

function Paragraph({ label }: ParagraphProps) {
  return <ParagraphStyled>{label}</ParagraphStyled>;
}

export default Paragraph;

Paragraph.defaultProps = {
  label: "",
};

Paragraph.propTypes = {
  label: PropTypes.string,
};
