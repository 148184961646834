import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { PostCompanyRequest } from "../../models/company";
import Input from "../../components/FormComponents/Input";
import Button from "../../components/FormComponents/Button";
import { postCompany } from "../../actions/company";

const FormGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, 264px);
  grid-row-gap: 16px;
  grid-column-gap: 26px;
`;

function CompanyAdd() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting },
  } = useForm<PostCompanyRequest>();
  const onSubmit = async (data: PostCompanyRequest) => {
    if (Object.keys(errors).length > 0 || isSubmitting) {
      return;
    }
    await postCompany(dispatch, { name: data.name, isPrivate: true })
      .then((response) => {
        if (response) {
          setValue("name", "");
        }
      })
      .catch(() => {});
  };

  return (
    <FormGrid onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="name"
        label="Company name"
        inputRef={register({ required: true })}
        type="text"
      />
      <Button type="submit" size="small" width="250px" disabled={isSubmitting}>
        {isSubmitting ? "Loading..." : "Add"}
      </Button>
    </FormGrid>
  );
}

export default CompanyAdd;
