import {
  getCompanyRequest,
  getCompanyUsersRequest,
  postCompanyRequest,
  postCompanyUserRequest,
} from "../api/company";
import {
  addCompanies,
  addCompany,
  setLoading,
} from "../reducers/company/companiesReducer";
import {
  addUserToCompany,
  setCompany,
  setUsersLoading,
} from "../reducers/company/companyReducer";
import { PostCompanyUserRequest } from "../models/company";

export const fetchCompanies = async (dispatch: any) => {
  dispatch(setLoading(true));
  const companies = await getCompanyRequest();
  dispatch(addCompanies(companies));
  dispatch(setLoading(false));
};

export const postCompany = async (
  dispatch: any,
  payload: { name: string; isPrivate: boolean }
) => {
  const company = await postCompanyRequest(payload);
  dispatch(addCompany(company));
  return company;
};

export const getCompanyUsers = async (dispatch: any, companyUUID: string) => {
  dispatch(setUsersLoading(true));
  const users = await getCompanyUsersRequest(companyUUID);
  dispatch(setCompany(users));
  dispatch(setUsersLoading(false));
  return users;
};

export const postCompanyUser = async (
  dispatch: any,
  payload: PostCompanyUserRequest
) => {
  const user = await postCompanyUserRequest(payload);
  if (user) dispatch(addUserToCompany(user));
  return user;
};
