import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MainLayout from "../../components/MainLayout";
import CompanyAdd from "./CompanyAdd";
import { fetchCompanies } from "../../actions/company";
import { Company } from "../../models/company";
import { RootState } from "../../store";
import Loader from "../../components/Loader";

const Heading = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #000;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
`;

const TableHeading = styled.th`
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  color: #666;
  padding: 10px 16px;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid #000;
`;

const TableData = styled.td`
  border-bottom: 1px solid #000;
  padding: 23px 16px;
`;

const OutlinedButton = styled.div`
  border: 1px solid #000000;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  padding: 4px 22px;
  cursor: pointer;
`;

function Companies() {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchCompanies(dispatch);
  }, [dispatch]);

  const companies: Company[] = useSelector(
    (state: any) => state.companies.data
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.companies.loading
  );

  const leftContent = (
    <>
      <Heading>Companies</Heading>
      <Table>
        <TableHead>
          <tr>
            <TableHeading>Company name</TableHeading>
            <TableHeading>Actions</TableHeading>
          </tr>
        </TableHead>
        <tbody>
          {companies.length
            ? companies.map((company) => (
                <tr key={company.uuid}>
                  <TableData style={{ fontWeight: "bold" }}>
                    {company.name}
                  </TableData>
                  <TableData>
                    <Link to={`company/${company.uuid}`}>
                      <OutlinedButton>View</OutlinedButton>
                    </Link>
                  </TableData>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      {loading && <Loader />}
    </>
  );

  const rightContent = (
    <>
      <div style={{ marginTop: 16 }}>
        Add Company:
        <CompanyAdd />
      </div>
    </>
  );

  return <MainLayout leftContent={leftContent} rightContent={rightContent} />;
}

export default Companies;
