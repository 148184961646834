import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import parseJwt from "../utils/jwtDecode";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const token = localStorage.getItem("bearerToken");
  const isLoggedIn = token && parseJwt(token).exp * 1000 > Date.now();
  if (!isLoggedIn) {
    localStorage.clear();
  }
  const renderRoute = (props: any) =>
    // eslint-disable-next-line react/jsx-props-no-spreading
    isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route render={renderRoute} {...rest} />;
};

export default withRouter(PrivateRoute);
