import { configureStore } from "@reduxjs/toolkit";
import companiesReducer from "./reducers/company/companiesReducer";
import companyReducer from "./reducers/company/companyReducer";

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    company: companyReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
