import API from "./api";
import {
  Company,
  CompanyUser,
  GetCompanyRequestResponse,
  PostCompanyRequest,
  PostCompanyUserRequest,
} from "../models/company";

export const getCompanyRequest: () => Promise<Company[] | null> = async () => {
  return API.get("company")
    .then((res) => {
      if (res.data.payload) {
        return res.data.payload;
      }

      if (res.data.error) {
        return null;
      }

      return null;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const postCompanyRequest = async (data: PostCompanyRequest) => {
  return API.post("company", data)
    .then((res) => {
      if (res.data.payload) {
        return res.data.payload as Company;
      }

      if (res.data.error) {
        return null;
      }

      return null;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getCompanyUsersRequest = async (companyUUID: string) => {
  return API.get(`company/user/${companyUUID}`)
    .then((res) => {
      if (res.data.payload) {
        return res.data.payload as GetCompanyRequestResponse;
      }

      if (res.data.error) {
        return null;
      }

      return null;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const postCompanyUserRequest = async (data: PostCompanyUserRequest) => {
  return API.post("company/user", data)
    .then((res) => {
      if (res.data.payload) {
        return res.data.payload.user as CompanyUser;
      }

      if (res.data.error) {
        return null;
      }

      return null;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
